import { Outlet, Link,NavLink  } from "react-router-dom";
import CommonSvg from "../Constant/CommonSvg";
import PropTypes from 'prop-types';


const Sidebar = () => {
  return (
    <>
    <div className="side-bar">
    <nav className="navbar navbar-expand-md navbar-dark bg-dark p-0">
    <a className="navbar-brand" href="/team"><img src={require('../../assets/images/logo.png')} alt="icon" className="brand-logo"/></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav">
        <li className="nav-item">

          <NavLink to="/team" activeClassName="active" className="nav-link" aria-current="page">
          <img src={CommonSvg.TeamSvg} alt="icon" />
          <span>Your Team</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/glass" activeClassName="active" className="nav-link" aria-current="page">
          <img src={CommonSvg.GlassSvg} alt="icon" />
          <span>Your Glass</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/review" activeClassName="active" className="nav-link" aria-current="page">
          <img src={CommonSvg.ReviewSvg} alt="icon" />
          <span>Review</span>
          </NavLink>
        </li>
         <li className="nav-item">
          <NavLink to="/terms&Sign" activeClassName="active" className="nav-link" aria-current="page">
          <img src={CommonSvg.TermsSvg} alt="icon" />
          <span>Terms & Sign</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/payments" activeClassName="active" className="nav-link" aria-current="page">
          <img src={CommonSvg.PaymentsSvg} alt="icon" />
          <span>Payments</span>
          </NavLink>
        </li>
         <li className="nav-item">
          <NavLink to="/progress" activeClassName="active" className="nav-link" aria-current="page">
          <img src={CommonSvg.ProgressSvg} alt="icon" />
          <span>Progress</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/about" activeClassName="active" className="nav-link" aria-current="page">
          <img src={CommonSvg.AboutSvg} alt="icon" />
          <span>About Us</span>
          </NavLink>
        </li>
      </ul>

  </div>

<div class="col d-flex align-items-center chatbot-sec py-lg-5 py-3"><div class="chatbot-icon"><a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="41.456" height="41.456" viewBox="0 0 41.456 41.456"><g id="Icon_ionic-ios-chatbubbles" data-name="Icon ionic-ios-chatbubbles" transform="translate(-3.375 -3.375)"><path id="Path_31" data-name="Path 31" d="M39.538,30.541a2.414,2.414,0,0,1,.329-1.216,3.356,3.356,0,0,1,.209-.309,16.147,16.147,0,0,0,2.75-9c.03-9.188-7.723-16.642-17.31-16.642A17.191,17.191,0,0,0,8.546,16.619a16.014,16.014,0,0,0-.369,3.408A16.9,16.9,0,0,0,25.218,36.879a20.523,20.523,0,0,0,4.7-.767c1.126-.309,2.242-.718,2.531-.827a2.635,2.635,0,0,1,.927-.169,2.591,2.591,0,0,1,1.007.2l5.65,2a1.347,1.347,0,0,0,.389.1.793.793,0,0,0,.8-.8,1.28,1.28,0,0,0-.05-.269Z" transform="translate(2.004)"></path><path id="Path_32" data-name="Path 32" d="M30.331,33.73c-.359.1-.817.209-1.315.319a18.393,18.393,0,0,1-3.388.448A16.9,16.9,0,0,1,8.587,17.646a18.841,18.841,0,0,1,.149-2.133c.06-.429.13-.857.229-1.276.1-.448.219-.9.349-1.335l-.8.708A14.83,14.83,0,0,0,3.375,24.761a14.665,14.665,0,0,0,2.471,8.172c.229.349.359.618.319.8s-1.186,6.179-1.186,6.179a.8.8,0,0,0,.269.767.812.812,0,0,0,.508.179.715.715,0,0,0,.289-.06l5.591-2.2a1.557,1.557,0,0,1,1.2.02,16.772,16.772,0,0,0,6.049,1.2A15.651,15.651,0,0,0,30.85,34.318s.319-.438.688-.957C31.168,33.491,30.75,33.62,30.331,33.73Z" transform="translate(0 3.976)"></path></g></svg></a></div></div>

</nav>
</div>

      <Outlet />
    </>
  )
};

export default Sidebar;