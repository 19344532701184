import React from 'react';
//import ReactDOM from 'react-dom/client';
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import PagesSvg from "../Constant/PagesSvg";
import background from "../../assets/images/close.svg";
import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';
class Progress extends React.Component {
constructor(props) {
super(props);
this.state = {animate: false};
this.state = {animateClass: ''};
AOS.init({ duration : 2000});
}
componentDidMount(){
this.timer = setInterval(
() => this.setState({animateClass:'position-with-animation'}),
100,
);
}
componentWillUnmount(){
// this.unlisten();
}
render() {
return <div className="container-fluid">
    <div className="row">
        <div className="col-md-3 px-0">
            <Sidebar />
        </div>
        <div className="col-md-9 payment-page position-relative">
            <div className="main-content align-items-start py-5">
                
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-6">
                            <div className="heading-section">
                                
                                <h1>Progress</h1>
                                
                            </div>
                        </div>
                        <div className="col-md-4 col-6 position-relative">
                            <div className="popup-cta-btn print-info-btn ms-auto">
                                <div className="info-btn-sec">
                                    <a href="#" className="btn info-popup-btn" data-bs-toggle="modal" href="#exampleModalToggle2" role="button"><img src={PagesSvg.InfoSvg} alt="icon" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row my-5">
                        <div className="col-12">
                        <div className="d-flex justify-content-center progress-step-main">
                            <div className={"d-sm-flex position-relative progress-step-active " + (this.state.animateClass != '' ? this.state.animateClass : 'position-without-animation')}>
                                <div className="step step-one active" data-aos="fade-up"
     data-aos-duration="3000">
                                <div className="step-inner">
                                                                       <div className="step-img"><svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51">
  <g id="step-graphic" transform="translate(-488 -421)">
    <circle id="Ellipse_25" data-name="Ellipse 25" cx="25.5" cy="25.5" r="25.5" transform="translate(488 421)" fill="#62bbbc"/>
    <g id="Group_86" data-name="Group 86">
      <line id="Line_25" data-name="Line 25" x2="10" y2="8" transform="translate(501.5 448.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="6"/>
      <line id="Line_26" data-name="Line 26" x1="14" y2="20" transform="translate(511.5 436.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="6"/>
    </g>
  </g>
</svg>
</div>

                                    <h5>Quote</h5>
                                    </div>
                                </div>
                                <div className="step step-two active" data-aos="fade-up"
     data-aos-duration="3000" data-aos-delay="100">
                                <div className="step-inner">
                                     <div className="step-img"><svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51">
  <g id="step-graphic" transform="translate(-488 -421)">
    <circle id="Ellipse_25" data-name="Ellipse 25" cx="25.5" cy="25.5" r="25.5" transform="translate(488 421)" fill="#62bbbc"/>
    <g id="Group_86" data-name="Group 86">
      <line id="Line_25" data-name="Line 25" x2="10" y2="8" transform="translate(501.5 448.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="6"/>
      <line id="Line_26" data-name="Line 26" x1="14" y2="20" transform="translate(511.5 436.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="6"/>
    </g>
  </g>
</svg>
</div>
                                    <h5>Confirmed</h5>
                                    </div>
                                </div>
                                <div className="step step-three active delivery-truck" data-aos="fade-up"
     data-aos-duration="3000" data-aos-delay="200">
                                <div className="step-inner">
                                     <div className="step-img"><svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51">
  <g id="step-graphic" transform="translate(-488 -421)">
    <circle id="Ellipse_25" data-name="Ellipse 25" cx="25.5" cy="25.5" r="25.5" transform="translate(488 421)" fill="#62bbbc"/>
    <g id="Group_86" data-name="Group 86">
      <line id="Line_25" data-name="Line 25" x2="10" y2="8" transform="translate(501.5 448.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="6"/>
      <line id="Line_26" data-name="Line 26" x1="14" y2="20" transform="translate(511.5 436.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="6"/>
    </g>
  </g>
</svg>
</div>
                                    <h5>Deposit Payed</h5>
                                    </div>
                                </div>                       
                        
                             <div className="step step-four inactive" data-aos="fade-up"
     data-aos-duration="3000" data-aos-delay="300">
     <div className="step-inner">
                                    <div className="step-img"><svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51">
  <g id="step-graphic" transform="translate(-488 -421)">
    <circle id="Ellipse_25" data-name="Ellipse 25" cx="25.5" cy="25.5" r="25.5" transform="translate(488 421)" fill="#62bbbc"/>
    <g id="Group_86" data-name="Group 86">
      <line id="Line_25" data-name="Line 25" x2="10" y2="8" transform="translate(501.5 448.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="6"/>
      <line id="Line_26" data-name="Line 26" x1="14" y2="20" transform="translate(511.5 436.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="6"/>
    </g>
  </g>
</svg>
</div>
                                    <h5>Survey<br/> 
Appointment</h5>
                                    </div>
                                </div>
                                <div className="step step-five inactive" data-aos="fade-up"
     data-aos-duration="3000" data-aos-delay="400">
     <div className="step-inner">
                                   
  <div className="step-img"><svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51">
  <g id="step-graphic" transform="translate(-488 -421)">
    <circle id="Ellipse_25" data-name="Ellipse 25" cx="25.5" cy="25.5" r="25.5" transform="translate(488 421)" fill="#62bbbc"/>
    <g id="Group_86" data-name="Group 86">
      <line id="Line_25" data-name="Line 25" x2="10" y2="8" transform="translate(501.5 448.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="6"/>
      <line id="Line_26" data-name="Line 26" x1="14" y2="20" transform="translate(511.5 436.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="6"/>
    </g>
  </g>
</svg>
</div>

                                    <h5>Install Date<br/> 
Confirmed</h5>
                                    </div>
                                </div>
                                <div className="step step-six inactive" data-aos="fade-up"
     data-aos-duration="3000" data-aos-delay="500">
     <div className="step-inner">
                                  
  <div className="step-img"><svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51">
  <g id="step-graphic" transform="translate(-488 -421)">
    <circle id="Ellipse_25" data-name="Ellipse 25" cx="25.5" cy="25.5" r="25.5" transform="translate(488 421)" fill="#62bbbc"/>
    <g id="Group_86" data-name="Group 86">
      <line id="Line_25" data-name="Line 25" x2="10" y2="8" transform="translate(501.5 448.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="6"/>
      <line id="Line_26" data-name="Line 26" x1="14" y2="20" transform="translate(511.5 436.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="6"/>
    </g>
  </g>
</svg>
</div>

                                    <h5>Stage<br/>
Payment </h5>
                                    </div>
                                </div>
                                <div className="step step-seven inactive" data-aos="fade-up"
     data-aos-duration="3000" data-aos-delay="600">
     <div className="step-inner">
                                   
  <div className="step-img"><svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51">
  <g id="step-graphic" transform="translate(-488 -421)">
    <circle id="Ellipse_25" data-name="Ellipse 25" cx="25.5" cy="25.5" r="25.5" transform="translate(488 421)" fill="#62bbbc"/>
    <g id="Group_86" data-name="Group 86">
      <line id="Line_25" data-name="Line 25" x2="10" y2="8" transform="translate(501.5 448.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="6"/>
      <line id="Line_26" data-name="Line 26" x1="14" y2="20" transform="translate(511.5 436.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="6"/>
    </g>
  </g>
</svg>
</div>

                                    <h5>Installation</h5>
                                    </div>
                                </div>
                                <div className="step step-eight inactive" data-aos="fade-up"
     data-aos-duration="3000" data-aos-delay="700">
     <div className="step-inner">
                                  
  <div className="step-img"><svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51">
  <g id="step-graphic" transform="translate(-488 -421)">
    <circle id="Ellipse_25" data-name="Ellipse 25" cx="25.5" cy="25.5" r="25.5" transform="translate(488 421)" fill="#62bbbc"/>
    <g id="Group_86" data-name="Group 86">
      <line id="Line_25" data-name="Line 25" x2="10" y2="8" transform="translate(501.5 448.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="6"/>
      <line id="Line_26" data-name="Line 26" x1="14" y2="20" transform="translate(511.5 436.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="6"/>
    </g>
  </g>
</svg>
</div>

                                    <h5>Final Payment</h5>
                                    </div>
                                </div>
                            </div>
                     </div>
                        </div>
                    </div>
                    <div className="row my-5">
                        <div className="col-md-6 pe-md-5">
                            <div className="notifications-sec">
                                <h3>Notifications</h3>
                                <ul className="ps-0">
                                    <li className="d-flex justify-content-between align-items-center">
                                        <h3 className="noti-label">Final payment</h3>
                                        <h3 className="noti-date">07/08/22</h3>
                                    </li>
                                    <li className="d-flex justify-content-between align-items-center">
                                        <h3 className="noti-label">Installed</h3>
                                        <h3 className="noti-date">20/08/22</h3>
                                    </li>
                                    <li className="d-flex justify-content-between align-items-center">
                                        <h3 className="noti-label">Site team lead confirmed</h3>
                                        <h3 className="noti-date">10/08/22</h3>
                                    </li>
                                    <li className="d-flex justify-content-between align-items-center">
                                        <h3 className="noti-label">Stage payment</h3>
                                        <h3 className="noti-date">07/09/22</h3>
                                    </li>
                                    <li className="d-flex justify-content-between align-items-center">
                                        <h3 className="noti-label">Install date confirmed</h3>
                                        <h3 className="noti-date">30/08/22</h3>
                                    </li>
                                    <li className="d-flex justify-content-between align-items-center">
                                        <h3 className="noti-label">Notification</h3>
                                        <h3 className="noti-date">25/08/22</h3>
                                    </li>
                                    <li className="d-flex justify-content-between align-items-center">
                                        <h3 className="noti-label">Notification</h3>
                                        <h3 className="noti-date">23/08/22</h3>
                                    </li>
                                    <li className="d-flex justify-content-between align-items-center">
                                        <h3 className="noti-label">Survey Appointment set</h3>
                                        <h3 className="noti-date">09/08/22</h3>
                                    </li>
                                    <li className="d-flex justify-content-between align-items-center">
                                        <h3 className="noti-label">Deposit Payed</h3>
                                        <h3 className="noti-date">05/08/22</h3>
                                    </li>
                                    <li className="d-flex justify-content-between align-items-center">
                                        <h3 className="noti-label">Confirmed</h3>
                                        <h3 className="noti-date">23/08/22</h3>
                                    </li>
                                    <li className="d-flex justify-content-between align-items-center">
                                        <h3 className="noti-label">Second Quoted</h3>
                                        <h3 className="noti-date">09/08/22</h3>
                                    </li>
                                    <li className="d-flex justify-content-between align-items-center">
                                        <h3 className="noti-label">Quoted</h3>
                                        <h3 className="noti-date">05/08/22</h3>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 ps-md-5">
                            <div className="contact-form-sec">
                                <h3>Contact Form</h3>
                                <select class="form-select" aria-label="Default select example">
                                    <option>Topic Selection</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <textarea id="w3review" name="w3review" rows="7" cols="50">Question</textarea>
                                <a href="#" class="btn btn-primary send-btn" role="button">Send</a>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <Footer/>
            </div>
        </div>
        <div class="modal blur-overlay fade print-popup-overlay" id="exampleModalToggle1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <div className="quote-image-sec">
                                    <img src={require('../../assets/images/quote_image.png')} alt="img" className="wc-video img-fluid"/>
                                    <div className="qis-content">
                                        <h5>Contract of works Terms & Conditions</h5>
                                        <img src={require('../../assets/images/logo.png')} alt="img" className="logoforpopup img-fluid"/>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-6">
                                <h1>Print a copy</h1>
                                <p>Click print or download to
                                receive your pdf copy </p>
                                <a href="#" className="btn btn-primary download-btn" role="button">Download</a>
                                <a href="#" className="btn btn-primary print-btn" role="button">Print</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal blur-overlay fade info-popup-overlay" id="exampleModalToggle2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div className="row align-items-center">
                            <div className="col-12">
                                
                                <h5>Stonly.com <br/>Support info</h5>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
    }
    }
    export default Progress;