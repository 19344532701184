import ImgIcon from '../../assets/images/icon_awesome-images.svg';
import ColapsibleIcon from '../../assets/images/ionic-md-arrow-dropup-circle.svg';
import PlayIcon from '../../assets/images/awesome-play-circle.svg';
import InfoIcon from '../../assets/images/material-info.svg';


export default{
	ImgicoSvg: ImgIcon,
	ColapsibleSvg: ColapsibleIcon,
	PlayicoSvg: PlayIcon,
	InfoicoSvg: InfoIcon,
}