import React from 'react';
//import ReactDOM from 'react-dom/client';
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import {withRouter} from "../Common/withRouter";
import PagesSvg from "../Constant/PagesSvg";
import Lottie from 'react-lottie';
import * as animationData from '../../assets/js/6699160.json'
import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';
import PropTypes from "prop-types";





var myTimer;
var myTimerD = '1';



class Start extends React.Component {

    static contextTypes = {
    router: PropTypes.object
  }

constructor(props, context) {
     super(props, context);
    this.state = {animate: false};
    this.state = {animateClass: '',isTeam:false};
    AOS.init({ duration : 2000});
}


yssRemove = (navigate) => {

   
 
    $(".yss-first-quote").css('opacity','0');
     $(".yss-lottie-ani").css('opacity','1');
     $(".yss-brand").css('opacity','1');

    myTimer = setTimeout(this.myGreeting, 3000);
    setTimeout(this.secSecction, 4000)
    setTimeout(this.removeSecction, 6000)
}

myGreeting() {
  $(".yss-lottie-ani").css({'bottom':'0%', 'opacity':'0'});
  clearTimeout(myTimer);

 
  
}

secSecction(){
    $(".yss-welcome-message").css({'top':'50%', 'opacity':'1'});
}

removeSecction = () => {
    $(".yss-main-wrapper").addClass("hide-wrapper");
    this.props.navigate('/team');
}


componentDidMount(){
this.timer = setInterval(
() => this.setState({animateClass:'width-with-animation'}),
100,
);
}



componentWillUnmount(){
// this.unlisten();
}
render() {
   let { isTeam } = this.state;

   

    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };


return <div className="container-fluid team-page">


    <div className="row yss-main-wrapper">
        <div className="col-12">
            <div className="yes-start-screen d-flex justify-content-center align-items-center flex-column">
                <a href="javascript:void(0)" className="yss-first-quote" onClick={this.yssRemove.bind(this)}>YES Glassing Quote Tool Moc1</a>
             <div className="yss-lottie-ani">           
                <Lottie options={defaultOptions}
              height={150}
              width={150}
              isStopped={false}
              isPaused={false}/>             
              <a href="/">Your personal quotation</a>
              </div>
              <a href="/" className="yss-welcome-message">Hi John & Amy,<br/>
thank you for choosing Yes Glassing  </a>
              <a className="yss-brand" href="#"><img src={require('../../assets/images/logo.png')} alt="icon" className="yss-logo"/></a>
            </div>
        </div>
    </div>


    </div>;
    }
    }
    export default withRouter(Start);