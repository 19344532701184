import React from 'react';
import CommonSvg from "../Constant/CommonSvg";
import Lottie from 'react-lottie';
import * as animationData from '../../assets/js/7280801.json'
import $ from 'jquery';
import { Link } from "react-router-dom";
//import ReactDOM from 'react-dom/client';
class Footer extends React.Component {
render() {


 const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };



return <div className="footer-section">

  <div class="container">
    <div class="row flex-md-nowrap">
    <div className="col d-flex align-items-center chatbot-sec py-md-5 py-3">
    <div className="chatbot-icon">
    <a href="#">

<svg xmlns="http://www.w3.org/2000/svg" width="41.456" height="41.456" viewBox="0 0 41.456 41.456">
  <g id="Icon_ionic-ios-chatbubbles" data-name="Icon ionic-ios-chatbubbles" transform="translate(-3.375 -3.375)">
    <path id="Path_31" data-name="Path 31" d="M39.538,30.541a2.414,2.414,0,0,1,.329-1.216,3.356,3.356,0,0,1,.209-.309,16.147,16.147,0,0,0,2.75-9c.03-9.188-7.723-16.642-17.31-16.642A17.191,17.191,0,0,0,8.546,16.619a16.014,16.014,0,0,0-.369,3.408A16.9,16.9,0,0,0,25.218,36.879a20.523,20.523,0,0,0,4.7-.767c1.126-.309,2.242-.718,2.531-.827a2.635,2.635,0,0,1,.927-.169,2.591,2.591,0,0,1,1.007.2l5.65,2a1.347,1.347,0,0,0,.389.1.793.793,0,0,0,.8-.8,1.28,1.28,0,0,0-.05-.269Z" transform="translate(2.004)"/>
    <path id="Path_32" data-name="Path 32" d="M30.331,33.73c-.359.1-.817.209-1.315.319a18.393,18.393,0,0,1-3.388.448A16.9,16.9,0,0,1,8.587,17.646a18.841,18.841,0,0,1,.149-2.133c.06-.429.13-.857.229-1.276.1-.448.219-.9.349-1.335l-.8.708A14.83,14.83,0,0,0,3.375,24.761a14.665,14.665,0,0,0,2.471,8.172c.229.349.359.618.319.8s-1.186,6.179-1.186,6.179a.8.8,0,0,0,.269.767.812.812,0,0,0,.508.179.715.715,0,0,0,.289-.06l5.591-2.2a1.557,1.557,0,0,1,1.2.02,16.772,16.772,0,0,0,6.049,1.2A15.651,15.651,0,0,0,30.85,34.318s.319-.438.688-.957C31.168,33.491,30.75,33.62,30.331,33.73Z" transform="translate(0 3.976)"/>
  </g>
</svg>

    </a>
    </div>
    </div>
      <div className="col d-md-flex d-none align-items-center py-md-5 py-3">
        <div className="contact-no">
          <h3>Contact Number<a href="#">0808 149 2159</a></h3>
        </div>
      </div>
       <div className="total-estimate-sec col d-flex align-items-start py-md-5 py-3">
        <div className="price-label">
          <h1>Your Total: </h1>
          <p>Subject to survey</p>
        </div>
         <div className="price-amount">
          <h1>£345.405</h1>
          <p>Including VAT</p>
        </div>
      </div>



      <div className="col d-flex justify-content-end align-items-center py-md-5 py-3">
        <div className="cta-btn footer-cta-btn wrap d-flex">
          <button className="button-transparent-bg border-0 bg-transparent"><a href="#" className="btn btn-primary prev-btn border-animation" role="button">BACK</a></button>
           <button className="button-lottie-ani border-0 bg-transparent">           
                <Lottie options={defaultOptions}
              height={40}
              width={150}
              isStopped={false}
              isPaused={false}/><Link to="/glass" className="btn btn-primary next-btn" role="button">Start</Link>
              </button>          
        </div>
      </div>
    </div>
  </div>
</div>;
}
}
export default Footer;