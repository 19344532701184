import React from 'react';
//import ReactDOM from 'react-dom/client';
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import PagesSvg from "../Constant/PagesSvg";
import background from "../../assets/images/close.svg";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';

class Terms extends React.Component {

  constructor() {
    super();
    this.state = {
      name: 'React'
    };
  AOS.init({ duration : 2000});
  }

render() {

const NextArrow: React.FC<ArrowsProps> = ({ onClick, className }) => (
  <div onClick={onClick} class="slick-prev"><img src={PagesSvg.CaArrowLeftIconSvg} alt="img" className="img-fluid" id="prv-img"/></div>
);

const PrevArrow: React.FC<ArrowsProps> = ({ onClick, className }) => ( <div onClick={onClick} class="slick-next"><img src={PagesSvg.CaArrowLeftIconSvg} alt="img" className="img-fluid" id="nxt-img"/></div>
);

var settings = {
    arrows:true,
    infinite: true,
    speed: 500,
    vertical:true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <NextArrow />,
    nextArrow: <PrevArrow />
  };



return <div className="container-fluid">
    <div className="row">
        <div className="col-md-3 px-0">
            <Sidebar />
        </div>
        <div className="col-md-9 terms-page position-relative">
            <div className="main-content align-items-start py-5">
                
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-6">
                            <div className="heading-section">
                                
                                <h1>Terms, Conditions & Sign</h1>
                                
                            </div>
                        </div>
                        <div className="col-md-4 col-6 position-relative">
                            <div className="popup-cta-btn print-info-btn ms-sm-auto">
                                <div className="print-popup-btn-sec">
                                    <a href="#" className="btn print-popup-btn" data-bs-toggle="modal" href="#exampleModalToggle1" role="button">
                                        Print a copy
                                    <img src={PagesSvg.PrintSvg} alt="icon" /></a>
                                </div>
                                <div className="info-btn-sec">
                                    <a href="#" className="btn info-popup-btn" data-bs-toggle="modal" href="#exampleModalToggle2" role="button"><img src={PagesSvg.InfoSvg} alt="icon" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row my-5">
                        <div className="col-lg-9 col-md-8 pe-md-5" data-aos="fade-up"
     data-aos-duration="3000">
                            <div className="terms-page-content">
                                <div className="tpc-scroll-area">
                                    <p>
                                    Please scroll down, review and sign at the bottom.</p>
                                    <p><b> What Are the Terms and Conditions and When Are They Needed?</b></p>
                                    <p>“Terms and Conditions” is the document governing the contractual relationship between the provider of a service and its user. On the web, this document is often also called “Terms of Service” (ToS), “Terms of Use”, EULA (“End-User License Agreement”), “General Conditions” or “Legal Notes”.</p>
                                    <p>The Terms and Conditions are nothing other than a contract in which the owner clarifies the conditions of use of its service. Some quick examples are the use of the content (copyright) , the rules that users must follow while interacting with one another on the website / app and, finally, rules related to the cancellation or suspension of a user’s account etc.</p>
                                    <p>Particular emphasis should be given to the limitation of liability clauses (and disclaimers) , for example the case of malfunctions of the app or website.</p>
                                    <p>The Terms and Conditions therefore, represent the document that helps in dealing with problems or preventing them in the first place. Because of that, the Terms and Conditions are fundamental in many cases in order to mount an adequate and proper defense.</p>
                                    <p><b>Terms of Service set the way in which your product, service or content may be used, in a legally binding way.</b> They are crucial for protecting your content from a copyright perspective as well as for protecting you from potential liabilities.</p>
                                    <p>🎙️<br/>
                                    Ask our experts live<br/> View a live demo and have your questions answered in real time by attending our free English “Our Terms and Conditions Generator – Protect your business from potential liabilities” webinar. Discover in practice how you can set legally binding rules for how your product, service, or content may be used.</p>
                                    <p>What should I do if I run an e-commerce website? Is it recommended to create a Terms and Conditions document?<br/>
                                    Yes, it is not only advisable but also often mandatory.</p>
                                    <p>Not only are terms critically important for protecting you from potential liabilities, but they regularly contain legally mandated information such as users’ rights, withdrawal or cancellation disclosures.</p>
                                    <p>Generally, a website / app should have an accurate and valid Terms of Service document in place whenever complex issues are at stake, such as in the case of e-commerce, where sensitive information such as payment data is processed.</p>
                                    <p>In e-commerce sites, the Terms of Service is typically the document that contains information related to conditions of sale and disclosures on methods of payment, shipping, delivery, withdrawals, and cancellation conditions etc. – as commonly required by consumer protection regulations. European providers of B2C services (selling goods / services) are also required to include, among other things, an Online Dispute Resolution (ODR) statement that explains the mandatory dispute resolution process online.</p>
                                    <p>💡 You can read more about eCommerce requirements here.</p>
                                    <p><b>Can I use a terms and conditions template? Despite how simple they appear, terms and conditions are meant to meet incredibly complex and highly specific scenarios. Because each terms and conditions document is a legally binding contract that is meant to protect you, the business owner, it’s imperative that the document matches your specific business processes, model, and remains up-to-date with the various laws referenced in its contents. Templates simply cannot do this, therefore, we strongly suggest that you avoid using templates. Read our extended answer to this question here.</b></p>
                                    <p><b>Terms of Use vs. Terms of Service: what’s the difference between Terms and Conditions, Terms of Service and Terms of Use?<br/>In general, there is no legal difference. Terms and conditions, terms of service and terms of use are names all used to refer to the same document. The particular name used at any point in time is simply a matter of preference.</b></p>                                    <p><b>Privacy Policy vs. Terms and Conditions: what’s the difference between these two legal documents?<br/>
                                Privacy policy and terms and conditions are both legally binding agreements, but:<br/></b></p>
                                <p><b>Privacy policies are legally required under most countries’ legislations. They protect and inform your users and declare your compliance with applicable privacy laws in a legally binding way. While they do give you some leeway in terms of stating things such as how you handle “do not track” requests, they are generally aimed at protecting the user (more in our Legal Requirements Overview).<br/> Terms and conditions are aimed at protecting the business (you). They give business owners the opportunity to set their rules (within applicable law) of how their service or product may be used including, but not limited to, things like copyright conditions, age limits, and the governing law of the contract. While terms are generally not legally required (like the privacy policy), it is essential for protecting your interests as a business owner.<br/> Our Ultra plan comes with all the benefits of the Pro, allowing you to:<br/></b></p>
                                <p><b>create a privacy and cookie policy<br/>
                                    create a terms and conditions document<br/>
                                    use the Cookie Solution up to 25k pageviews/mo<br/>
                                for one site/app in one language. Check our pricing for more details.</b></p>
                                <p><b>How iubenda can help you generate and manage a Terms and Conditions document Signature Draw Type Upload</b></p>
                                <h2>Signature</h2>
                                <div className="signature-sec">
                                    <nav>
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button className="nav-link active" id="nav-draw-tab" data-bs-toggle="tab" data-bs-target="#nav-draw" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Draw</button>
                                            <button className="nav-link" id="nav-type-tab" data-bs-toggle="tab" data-bs-target="#nav-type" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Type</button>
                                            <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Upload</button>
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade show active" id="nav-draw" role="tabpanel" aria-labelledby="nav-draw-tab">
                                            <div className="signature-draw">
                                                <h1>Ed Dench</h1>
                                            </div>
                                            <a href="#" className="btn accept-sign-btn" role="button">Accept & Sign</a>
                                        </div>
                                        <div className="tab-pane fade" id="nav-type" role="tabpanel" aria-labelledby="nav-type-tab">...</div>
                                        <div className="tab-pane fade" id="nav-upload" role="tabpanel" aria-labelledby="nav-upload-tab">...</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 ps-md-5">
                        <div className="customer-review-sec">
                            <h3>Customer Review</h3>

<div className="vertical-carousel">
 <Slider {...settings}>
      <div >
        <img src={require('../../assets/images/cr01.png')} alt="img" className="review-video img-fluid slideh" />
      </div>
      
      <div >
        <img src={require('../../assets/images/cr02.png')} alt="img" className="review-video img-fluid slideh" />
      </div>
      
      <div >
        <img src={require('../../assets/images/cr03.png')} alt="img" className="review-video img-fluid slideh" />
      </div>
      
      <div >
        <img src={require('../../assets/images/cr04.png')} alt="img" className="review-video img-fluid slideh" />
      </div>
      
      <div >
        <img src={require('../../assets/images/cr05.png')} alt="img" className="review-video img-fluid slideh" />
      </div>
      
      <div >
        <img src={require('../../assets/images/cr06.png')} alt="img" className="review-video img-fluid slideh" />
      </div>
      
      <div >
        <img src={require('../../assets/images/cr07.png')} alt="img" className="review-video img-fluid slideh" />
      </div>
      
      <div >
        <img src={require('../../assets/images/cr01.png')} alt="img" className="review-video img-fluid slideh" />
      </div>
      
      <div >
        <img src={require('../../assets/images/cr02.png')} alt="img" className="review-video img-fluid slideh" />
      </div>
      

    </Slider>

</div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
            </div>
            
        </div>
    </div>
    <div class="modal blur-overlay fade print-popup-overlay" id="exampleModalToggle1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <div className="row align-items-center">
                        <div className="col-sm-6">
                            <div className="quote-image-sec">
                                <img src={require('../../assets/images/quote_image.png')} alt="img" className="wc-video img-fluid"/>
                                <div className="qis-content">
                                    <h5>Contract of works Terms & Conditions</h5>
                                    <img src={require('../../assets/images/logo.png')} alt="img" className="logoforpopup img-fluid"/>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-sm-6">
                            <h1>Print a copy</h1>
                            <p>Click print or download to
                            receive your pdf copy </p>
                            <a href="#" className="btn btn-primary download-btn" role="button">Download</a>
                            <a href="#" className="btn btn-primary print-btn" role="button">Print</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal blur-overlay fade info-popup-overlay" id="exampleModalToggle2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <div className="row align-items-center">
                        <div className="col-12">
                            
                            <h5>Stonly.com <br/>Support info</h5>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>;
}
}
export default Terms;