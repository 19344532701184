import React from 'react';
//import ReactDOM from 'react-dom/client';
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import PagesSvg from "../Constant/PagesSvg";
import background from "../../assets/images/close.svg";
import LeftNavButton from './LeftNavButton';
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';


class Payments extends React.Component {

  constructor() {
    super();
    this.state = {
      name: 'React'
    };
  AOS.init({ duration : 2000});
  }

render() {

const NextArrow: React.FC<ArrowsProps> = ({ onClick, className }) => (
  <div onClick={onClick} class="slick-prev"><img src={PagesSvg.CaArrowLeftIconSvg} alt="img" className="img-fluid" id="prv-img"/></div>
);

const PrevArrow: React.FC<ArrowsProps> = ({ onClick, className }) => ( <div onClick={onClick} class="slick-next"><img src={PagesSvg.CaArrowLeftIconSvg} alt="img" className="img-fluid" id="nxt-img"/></div>
);

var settings = {
    arrows:true,
    infinite: true,
    speed: 500,
    vertical:true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <NextArrow />,
    nextArrow: <PrevArrow />
  };



return <div className="container-fluid">
    <div className="row">
        <div className="col-md-3 px-0">
            <Sidebar />
        </div>
        <div className="col-md-9 payment-page position-relative">
            <div className="main-content align-items-start py-5">
                
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-6">
                            <div className="heading-section">
                                
                                <h1>Payments</h1>
                                
                            </div>
                        </div>
                        <div className="col-md-4 col-6 position-relative">
                            <div className="popup-cta-btn print-info-btn ms-auto">
                                <div className="info-btn-sec">
                                    <a href="#" className="btn info-popup-btn" data-bs-toggle="modal" href="#exampleModalToggle2" role="button"><img src={PagesSvg.InfoSvg} alt="icon" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row my-5">
                        <div className="col-lg-9 col-md-8 pe-lg-5">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="payment-page-content">
                                        <div className="payment-count-area">
                                            <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been</p>
                                            
                                            <div className="payment-stages-sec" data-aos="fade-down" data-aos-duration="2000">
                                                <table className="payment-stages">
                                                    <tr>
                                                        <th>Deposit Total:</th>
                                                        <th>£138,201<br/><span>Including VAT</span></th>
                                                        <th>Outstanding</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Stage payment:</td>
                                                        <td>£102,602<br/><span>Including VAT</span></td>
                                                        <td>Outstanding</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Final payment:</td>
                                                        <td>£102,602<br/><span>Including VAT</span></td>
                                                        <td>Outstanding</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 d-flex align-items-end justify-content-center">
                                    <div className="total-price-box">
                                        <h3>
                                        <span>Quote Price</span><br/>
                                        £345.405
                                        </h3>
                                        <h3>
                                        <span>Remaining</span><br/>
                                        £207.204
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-5" data-aos="fade-right" data-aos-duration="2000">
                                <div className="col-lg-5 col-sm-6">
                                    <div className="card-sec">
                                        <a href="#" data-bs-toggle="modal" href="#paymentToggle" role="button">
                                            <h3>Secure<br/> Card Payment</h3>
                                            <img src={PagesSvg.CardSvg} alt="icon" className="img-fluid" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-sm-6">
                                    <div className="card-sec">
                                        <a href="#" data-bs-toggle="modal" href="#paymentToggle" role="button">
                                            <h3>International<br/> Bank Transfer</h3>
                                            <img src={PagesSvg.CardtransferIconSvg} alt="icon" className="img-fluid" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-sm-6">
                                    <div className="card-sec">
                                        <a href="#" data-bs-toggle="modal" href="#paymentToggle" role="button">
                                            <h3>Apply Pay</h3>
                                            <img src={PagesSvg.ApplePayIconSvg} alt="icon" className="img-fluid" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-sm-6">
                                    <div className="card-sec">
                                        <a href="#" data-bs-toggle="modal" href="#paymentToggle" role="button">
                                            <h3>Google Pay</h3>
                                            <img src={PagesSvg.GooglePayIconSvg} alt="icon" className="img-fluid" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 ps-md-5">
                            <div className="customer-review-sec">
                                <h3>Customer Review</h3>
<div className="vertical-carousel">
 <Slider {...settings}>
      <div >
        <img src={require('../../assets/images/cr01.png')} alt="img" className="review-video img-fluid slideh" />
      </div>
      
      <div >
        <img src={require('../../assets/images/cr02.png')} alt="img" className="review-video img-fluid slideh" />
      </div>
      
      <div >
        <img src={require('../../assets/images/cr03.png')} alt="img" className="review-video img-fluid slideh" />
      </div>
      
      <div >
        <img src={require('../../assets/images/cr04.png')} alt="img" className="review-video img-fluid slideh" />
      </div>
      
      <div >
        <img src={require('../../assets/images/cr05.png')} alt="img" className="review-video img-fluid slideh" />
      </div>
      
      <div >
        <img src={require('../../assets/images/cr06.png')} alt="img" className="review-video img-fluid slideh" />
      </div>
      
      <div >
        <img src={require('../../assets/images/cr07.png')} alt="img" className="review-video img-fluid slideh" />
      </div>
      
      <div >
        <img src={require('../../assets/images/cr01.png')} alt="img" className="review-video img-fluid slideh" />
      </div>
      
      <div >
        <img src={require('../../assets/images/cr02.png')} alt="img" className="review-video img-fluid slideh" />
      </div>
      

    </Slider>

</div>
                            </div>
                        </div>
                    </div>
                </div>
          
                </div>
                    <Footer/>  
            </div>
        </div>
        <div class="modal blur-overlay fade payment-popup-overlay" id="paymentToggle" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                    <div className="row">
                    <div className="col-12">
                    <div className="blk-logo text-center">
                    <img src={require('../../assets/images/ygs_logo_black.png')} alt="img" className="logoforpopup img-fluid"/>
                    </div>
                    </div>
                    </div>
                        <div className="row">
                          <div className="col-12">
                          <form className="payment-form d-flex flex-wrap">
                          <label className="">Card information</label>
                          <div className="input-group">
                           <input type="text" className="form-control" placeholder="1234 1234 1234 1234"/>
                            <span className="input-group-img">
                            <img src={require('../../assets/images/cards.png')} alt="img" className="cards-pp img-fluid"/>
                            </span>
                           </div>
                           <div className="input-group w-50 border-top-0 rounded-top-0 border-end-0">
                           <input type="text" className="form-control" placeholder="MM / YY"/>
                            </div>
                            <div className="input-group w-50 border-top-0">
                            <input type="text" className="form-control" placeholder="CVC"/>
                            <span className="input-group-img">
                             <img src={require('../../assets/images/135card.png')} alt="img" className="cards-pp img-fluid"/>
                              </span>  
                           </div>
                           <label className="mt-4">Name on card</label>
                          <div className="input-group">
                           <input type="text" className="form-control" placeholder=""/>
                           </div>

                           <label className="mt-4">Country or region</label>
                          <div className="input-group">
                          <select class="form-select border-0" id="inputGroupSelect01">
    <option selected>United Kingdom </option>
    <option value="1">USA</option>
    <option value="2">India</option>
    <option value="3">Australia</option>
  </select>
    </div>
    <div className="input-group border-top-0">
                           <input type="text" className="form-control" placeholder="Postcode"/>
                           </div>

                            <a href="#" className="btn btn-primary paymentpp-btn mt-4" role="button">Pay £138,201.00</a>
                          </form>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal blur-overlay fade info-popup-overlay" id="exampleModalToggle2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div className="row align-items-center">
                            <div className="col-12">
                                
                                <h5>Stonly.com <br/>Support info</h5>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
    }
    }
    export default Payments;