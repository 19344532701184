import React,{Component} from 'react';
//import ReactDOM from 'react-dom/client';
import $ from 'jquery';
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import PagesSvg from "../Constant/PagesSvg";
import background from "../../assets/images/close.svg";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
class Review extends React.Component {

componentDidMount(){
    var html = $('.owl-dots').prop('outerHTML');
    var jh = $('#prv-img').prop('outerHTML');
    var kh = $('#nxt-img').prop('outerHTML');

    $('.owl-dots').remove();
    $('.owl-prev').html(jh);  
    $('.owl-next').html(kh);  
    $('.owl-prev').after(html);  
}


render() {



const options = {
margin: 30,
responsiveClass: true,
nav: true,
dots: true,
autoplay: true,
navText: ["Prev", "Next"],
smartSpeed: 1000,
responsive: {
0: {
items: 1,
margin: 10,
},
480: {
items: 2,
margin: 15,
},
600: {
items: 2,
},
700: {
items: 2,
},
1000: {
items: 3,
}
},
};



return <div className="container-fluid">
    <div className="row">
        <div className="col-md-3 px-0">
            <Sidebar />
        </div>
        <div className="col-md-9 review-page position-relative">
            <div className="main-content align-items-start py-5 flex-column">
                
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-6">
                            
                            <div className="heading-section">
                                
                                <h1> Please review and confirm your quote</h1>
                                
                            </div>
                        </div>
                        <div className="col-md-4 col-6 position-relative">
                            <div className="popup-cta-btn print-info-btn ms-auto">
                                <div className="print-popup-btn-sec">
                                    <a href="#" className="btn print-popup-btn" data-bs-toggle="modal" href="#exampleModalToggle1" role="button">
                                        Print a copy
                                    <img src={PagesSvg.PrintSvg} alt="icon" /></a>
                                </div>
                                <div className="info-btn-sec">
                                    <a href="#" className="btn info-popup-btn" data-bs-toggle="modal" href="#exampleModalToggle2" role="button"><img src={PagesSvg.InfoSvg} alt="icon" /></a>
                                </div>
                            </div>
                        </div>
                    </div>                                     
                </div>
                <div className="container-fluid px-md-0">
                  <OwlCarousel
                    className="owl-theme review-carousel" data-aos="fade-right" data-aos-duration="2000"
                    {...options}>

                {/* slide item 01 start */}
                    <div className="quote-confirm-sec">
                        <div className="quote-confirm-box">
                            <div className="qc-header">
                                <h3 className="title">Exterior Door 1</h3>
                                <h3 className="price">£10,114.00</h3>
                            </div>
                            <img src={require('../../assets/images/43f92ffb327ae345f47eca91d0d6089d.jpg')} alt="img" className="wc-video img-fluid" id="pr-img"/>
                            <div className="qc-des">
                                <p>To supply and install 1 No. 5 Panel Sunflex  SF55i Sliding Folding Doors</p>
                                <ul className="qc-list">
                                    <li>2 x enhancements added</li>
                                    <li>Date Added 23/04/22</li>
                                </ul>
                                <div className="qc-button">
                                    <a href="#" className="btn btn-edit" role="button">Edit</a>
                                    <a href="#" className="btn btn-request" data-bs-toggle="modal" href="#requestModalToggle" role="button">Make a request</a>
                                    <a href="#" className="btn btn-delete" data-bs-toggle="modal" href="#deleteModalToggle" role="button"><img src={PagesSvg.TrashSvg} alt="icon" className="img-fluid" /></a>
                                    <a href="#" className="btn btn-confirm" role="button">Confirm</a>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* slide item 01 end */}

                    { /* Second Slide Start */ }
                    
                    <div className="quote-confirm-sec">
                        <div className="quote-confirm-box">
                            <div className="qc-header">
                                <h3 className="title">Master Bedroom Sky</h3>
                                <h3 className="price">£10,114.00</h3>
                            </div>
                            <img src={require('../../assets/images/43f92ffb327ae345f47eca91d0d6089d.jpg')} alt="img" className="wc-video img-fluid"/>
                            <div className="qc-des">
                                <p>To supply and install 1 No. 5 Panel Sunflex  SF55i Sliding Folding Doors</p>
                                <ul className="qc-list">
                                    <li>2 x enhancements added</li>
                                    <li>Date Added 23/04/22</li>
                                </ul>
                                <div className="qc-button">
                                    <a href="#" className="btn btn-edit" role="button">Edit</a>
                                    <a href="#" className="btn btn-request" data-bs-toggle="modal" href="#requestModalToggle" role="button">Make a request</a>
                                    <a href="#" className="btn btn-delete" data-bs-toggle="modal" href="#deleteModalToggle" role="button"><img src={PagesSvg.TrashSvg} alt="icon" className="img-fluid" /></a>
                                    <a href="#" className="btn btn-confirm" role="button">Confirm</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /* Second Slide End */ }
                    { /* Third Slide Start */ }
                    
                    <div className="quote-confirm-sec">
                        <div className="quote-confirm-box">
                            <div className="qc-header">
                                <h3 className="title">Master Bedroom Sky</h3>
                                <h3 className="price">£10,114.00</h3>
                            </div>
                            <img src={require('../../assets/images/43f92ffb327ae345f47eca91d0d6089d.jpg')} alt="img" className="wc-video img-fluid"/>
                            <div className="qc-des">
                                <p>To supply and install 1 No. 5 Panel Sunflex  SF55i Sliding Folding Doors</p>
                                <ul className="qc-list">
                                    <li>2 x enhancements added</li>
                                    <li>Date Added 23/04/22</li>
                                </ul>
                                <div className="qc-button">
                                    <a href="#" className="btn btn-edit" role="button">Edit</a>
                                    <a href="#" className="btn btn-request" data-bs-toggle="modal" href="#requestModalToggle" role="button">Make a request</a>
                                    <a href="#" className="btn btn-delete" data-bs-toggle="modal" href="#deleteModalToggle" role="button"><img src={PagesSvg.TrashSvg} alt="icon" className="img-fluid" /></a>
                                    <a href="#" className="btn btn-confirm" role="button">Confirm</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /* Third Slide End */ }
                    { /* Fourth Slide Start */ }
                    
                    <div className="quote-confirm-sec">
                        <div className="quote-confirm-box">
                            <div className="qc-header">
                                <h3 className="title">Master Bedroom Sky</h3>
                                <h3 className="price">£10,114.00</h3>
                            </div>
                            <img src={require('../../assets/images/43f92ffb327ae345f47eca91d0d6089d.jpg')} alt="img" className="wc-video img-fluid"/>
                            <div className="qc-des">
                                <p>To supply and install 1 No. 5 Panel Sunflex  SF55i Sliding Folding Doors</p>
                                <ul className="qc-list">
                                    <li>2 x enhancements added</li>
                                    <li>Date Added 23/04/22</li>
                                </ul>
                                <div className="qc-button">
                                    <a href="#" className="btn btn-edit" role="button">Edit</a>
                                    <a href="#" className="btn btn-request" data-bs-toggle="modal" href="#requestModalToggle" role="button">Make a request</a>
                                    <a href="#" className="btn btn-delete" data-bs-toggle="modal" href="#deleteModalToggle" role="button"><img src={PagesSvg.TrashSvg} alt="icon" className="img-fluid" /></a>
                                    <a href="#" className="btn btn-confirm" role="button">Confirm</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    { /* Fourth Slide End */ }

                {/* slide item 05 start */}
                    <div className="quote-confirm-sec">
                        <div className="quote-confirm-box">
                            <div className="qc-header">
                                <h3 className="title">Exterior Door 1</h3>
                                <h3 className="price">£10,114.00</h3>
                            </div>
                            <img src={require('../../assets/images/43f92ffb327ae345f47eca91d0d6089d.jpg')} alt="img" className="wc-video img-fluid" id="pr-img"/>
                            <div className="qc-des">
                                <p>To supply and install 1 No. 5 Panel Sunflex  SF55i Sliding Folding Doors</p>
                                <ul className="qc-list">
                                    <li>2 x enhancements added</li>
                                    <li>Date Added 23/04/22</li>
                                </ul>
                                <div className="qc-button">
                                    <a href="#" className="btn btn-edit" role="button">Edit</a>
                                    <a href="#" className="btn btn-request" data-bs-toggle="modal" href="#requestModalToggle" role="button">Make a request</a>
                                    <a href="#" className="btn btn-delete" data-bs-toggle="modal" href="#deleteModalToggle" role="button"><img src={PagesSvg.TrashSvg} alt="icon" className="img-fluid" /></a>
                                    <a href="#" className="btn btn-confirm" role="button">Confirm</a>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* slide item 05 end */}


                 {/* slide item 06 start */}
                    <div className="quote-confirm-sec">
                        <div className="quote-confirm-box">
                            <div className="qc-header">
                                <h3 className="title">Exterior Door 1</h3>
                                <h3 className="price">£10,114.00</h3>
                            </div>
                            <img src={require('../../assets/images/43f92ffb327ae345f47eca91d0d6089d.jpg')} alt="img" className="wc-video img-fluid" id="pr-img"/>
                            <div className="qc-des">
                                <p>To supply and install 1 No. 5 Panel Sunflex  SF55i Sliding Folding Doors</p>
                                <ul className="qc-list">
                                    <li>2 x enhancements added</li>
                                    <li>Date Added 23/04/22</li>
                                </ul>
                                <div className="qc-button">
                                    <a href="#" className="btn btn-edit" role="button">Edit</a>
                                    <a href="#" className="btn btn-request" data-bs-toggle="modal" href="#requestModalToggle" role="button">Make a request</a>
                                    <a href="#" className="btn btn-delete" data-bs-toggle="modal" href="#deleteModalToggle" role="button"><img src={PagesSvg.TrashSvg} alt="icon" className="img-fluid" /></a>
                                    <a href="#" className="btn btn-confirm" role="button">Confirm</a>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* slide item 06 end */}


                 {/* slide item 06 start */}
                    <div className="quote-confirm-sec">
                        <div className="quote-confirm-box">
                            <div className="qc-header">
                                <h3 className="title">Exterior Door 1</h3>
                                <h3 className="price">£10,114.00</h3>
                            </div>
                            <img src={require('../../assets/images/43f92ffb327ae345f47eca91d0d6089d.jpg')} alt="img" className="wc-video img-fluid" id="pr-img"/>
                            <div className="qc-des">
                                <p>To supply and install 1 No. 5 Panel Sunflex  SF55i Sliding Folding Doors</p>
                                <ul className="qc-list">
                                    <li>2 x enhancements added</li>
                                    <li>Date Added 23/04/22</li>
                                </ul>
                                <div className="qc-button">
                                    <a href="#" className="btn btn-edit" role="button">Edit</a>
                                    <a href="#" className="btn btn-request" data-bs-toggle="modal" href="#requestModalToggle" role="button">Make a request</a>
                                    <a href="#" className="btn btn-delete" data-bs-toggle="modal" href="#deleteModalToggle" role="button"><img src={PagesSvg.TrashSvg} alt="icon" className="img-fluid" /></a>
                                    <a href="#" className="btn btn-confirm" role="button">Confirm</a>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* slide item 06 end */}


                 {/* slide item 06 start */}
                    <div className="quote-confirm-sec">
                        <div className="quote-confirm-box">
                            <div className="qc-header">
                                <h3 className="title">Exterior Door 1</h3>
                                <h3 className="price">£10,114.00</h3>
                            </div>
                            <img src={require('../../assets/images/43f92ffb327ae345f47eca91d0d6089d.jpg')} alt="img" className="wc-video img-fluid" id="pr-img"/>
                            <div className="qc-des">
                                <p>To supply and install 1 No. 5 Panel Sunflex  SF55i Sliding Folding Doors</p>
                                <ul className="qc-list">
                                    <li>2 x enhancements added</li>
                                    <li>Date Added 23/04/22</li>
                                </ul>
                                <div className="qc-button">
                                    <a href="#" className="btn btn-edit" role="button">Edit</a>
                                    <a href="#" className="btn btn-request" data-bs-toggle="modal" href="#requestModalToggle" role="button">Make a request</a>
                                    <a href="#" className="btn btn-delete" data-bs-toggle="modal" href="#deleteModalToggle" role="button"><img src={PagesSvg.TrashSvg} alt="icon" className="img-fluid" /></a>
                                    <a href="#" className="btn btn-confirm" role="button">Confirm</a>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* slide item 06 end */}


                 {/* slide item 06 start */}
                    <div className="quote-confirm-sec">
                        <div className="quote-confirm-box">
                            <div className="qc-header">
                                <h3 className="title">Exterior Door 1</h3>
                                <h3 className="price">£10,114.00</h3>
                            </div>
                            <img src={require('../../assets/images/43f92ffb327ae345f47eca91d0d6089d.jpg')} alt="img" className="wc-video img-fluid" id="pr-img"/>
                            <div className="qc-des">
                                <p>To supply and install 1 No. 5 Panel Sunflex  SF55i Sliding Folding Doors</p>
                                <ul className="qc-list">
                                    <li>2 x enhancements added</li>
                                    <li>Date Added 23/04/22</li>
                                </ul>
                                <div className="qc-button">
                                    <a href="#" className="btn btn-edit" role="button">Edit</a>
                                    <a href="#" className="btn btn-request" data-bs-toggle="modal" href="#requestModalToggle" role="button">Make a request</a>
                                    <a href="#" className="btn btn-delete" data-bs-toggle="modal" href="#deleteModalToggle" role="button"><img src={PagesSvg.TrashSvg} alt="icon" className="img-fluid" /></a>
                                    <a href="#" className="btn btn-confirm" role="button">Confirm</a>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* slide item 06 end */}



                 {/* slide item 06 start */}
                    <div className="quote-confirm-sec">
                        <div className="quote-confirm-box">
                            <div className="qc-header">
                                <h3 className="title">Exterior Door 1</h3>
                                <h3 className="price">£10,114.00</h3>
                            </div>
                            <img src={require('../../assets/images/43f92ffb327ae345f47eca91d0d6089d.jpg')} alt="img" className="wc-video img-fluid" id="pr-img"/>
                            <div className="qc-des">
                                <p>To supply and install 1 No. 5 Panel Sunflex  SF55i Sliding Folding Doors</p>
                                <ul className="qc-list">
                                    <li>2 x enhancements added</li>
                                    <li>Date Added 23/04/22</li>
                                </ul>
                                <div className="qc-button">
                                    <a href="#" className="btn btn-edit" role="button">Edit</a>
                                    <a href="#" className="btn btn-request" data-bs-toggle="modal" href="#requestModalToggle" role="button">Make a request</a>
                                    <a href="#" className="btn btn-delete" data-bs-toggle="modal" href="#deleteModalToggle" role="button"><img src={PagesSvg.TrashSvg} alt="icon" className="img-fluid" /></a>
                                    <a href="#" className="btn btn-confirm" role="button">Confirm</a>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* slide item 06 end */}


                 {/* slide item 06 start */}
                    <div className="quote-confirm-sec">
                        <div className="quote-confirm-box">
                            <div className="qc-header">
                                <h3 className="title">Exterior Door 1</h3>
                                <h3 className="price">£10,114.00</h3>
                            </div>
                            <img src={require('../../assets/images/43f92ffb327ae345f47eca91d0d6089d.jpg')} alt="img" className="wc-video img-fluid" id="pr-img"/>
                            <div className="qc-des">
                                <p>To supply and install 1 No. 5 Panel Sunflex  SF55i Sliding Folding Doors</p>
                                <ul className="qc-list">
                                    <li>2 x enhancements added</li>
                                    <li>Date Added 23/04/22</li>
                                </ul>
                                <div className="qc-button">
                                    <a href="#" className="btn btn-edit" role="button">Edit</a>
                                    <a href="#" className="btn btn-request" data-bs-toggle="modal" href="#requestModalToggle" role="button">Make a request</a>
                                    <a href="#" className="btn btn-delete" data-bs-toggle="modal" href="#deleteModalToggle" role="button"><img src={PagesSvg.TrashSvg} alt="icon" className="img-fluid" /></a>
                                    <a href="#" className="btn btn-confirm" role="button">Confirm</a>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* slide item 06 end */}


                 {/* slide item 06 start */}
                    <div className="quote-confirm-sec">
                        <div className="quote-confirm-box">
                            <div className="qc-header">
                                <h3 className="title">Exterior Door 1</h3>
                                <h3 className="price">£10,114.00</h3>
                            </div>
                            <img src={require('../../assets/images/43f92ffb327ae345f47eca91d0d6089d.jpg')} alt="img" className="wc-video img-fluid" id="pr-img"/>
                            <div className="qc-des">
                                <p>To supply and install 1 No. 5 Panel Sunflex  SF55i Sliding Folding Doors</p>
                                <ul className="qc-list">
                                    <li>2 x enhancements added</li>
                                    <li>Date Added 23/04/22</li>
                                </ul>
                                <div className="qc-button">
                                    <a href="#" className="btn btn-edit" role="button">Edit</a>
                                    <a href="#" className="btn btn-request" data-bs-toggle="modal" href="#requestModalToggle" role="button">Make a request</a>
                                    <a href="#" className="btn btn-delete" data-bs-toggle="modal" href="#deleteModalToggle" role="button"><img src={PagesSvg.TrashSvg} alt="icon" className="img-fluid" /></a>
                                    <a href="#" className="btn btn-confirm" role="button">Confirm</a>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* slide item 06 end */}

                    
                    </OwlCarousel>
                     <img src={PagesSvg.CaArrowLeftIconSvg} alt="img" className="review-prv-img d-none img-fluid" id="prv-img"/>
                     <img src={PagesSvg.CaArrowRightIconSvg} alt="img" className="review-nxt-img d-none img-fluid" id="nxt-img"/>
                </div>
            </div>
            <Footer/>
            </div>
        </div>
        <div class="modal blur-overlay fade print-popup-overlay" id="exampleModalToggle1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <div className="quote-image-sec">
                                    <img src={require('../../assets/images/quote_image.png')} alt="img" className="wc-video img-fluid"/>
                                    <div className="qis-content">
                                        <h5>Your Quote</h5>
                                        <img src={require('../../assets/images/logo.png')} alt="img" className="logoforpopup img-fluid"/>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-sm-6">
                                <h1>Print a copy</h1>
                                <p>Click print or download to
                                receive your pdf copy </p>
                                <a href="#" className="btn btn-primary download-btn" role="button">Download</a>
                                <a href="#" className="btn btn-primary print-btn" role="button">Print</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal blur-overlay fade info-popup-overlay" id="exampleModalToggle2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div className="row align-items-center">
                            <div className="col-12">
                                
                                <h5>Stonly.com <br/>Support info</h5>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal blur-overlay fade request-form-overlay" id="requestModalToggle" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="rf-left-sec">
                                    <div className="quote-confirm-box">
                                        <div className="qc-header">
                                            <h3 className="title">Exterior Door 1</h3>
                                            <h3 className="price">£10,114.00</h3>
                                        </div>
                                        <img src="/static/media/43f92ffb327ae345f47eca91d0d6089d.b70cd5f8ec9ac9141a2e.jpg" alt="img" className="wc-video img-fluid"/>
                                        <div className="qc-des">
                                            <p>To supply and install 1 No. 5 Panel Sunflex SF55i Sliding Folding Doors</p>
                                            <ul className="qc-list">
                                                <li>2 x enhancements added</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-md-6">
                                <div className="popup-request-form">
                                    <h1>Request form</h1>
                                    <select class="form-select" aria-label="Default select example">
                                        <option selected>Topic Selection</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                    <textarea id="w3review" name="w3review" rows="4" cols="50">Question</textarea>
                                    <a href="#" className="btn btn-primary send-btn" role="button">Send</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal blur-overlay fade request-form-overlay request-form-delete" id="deleteModalToggle" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <div className="rf-left-sec">
                                    <div className="quote-confirm-box">
                                        <div className="qc-header">
                                            <h3 className="title">Exterior Door 1</h3>
                                            <h3 className="price">£10,114.00</h3>
                                        </div>
                                        <img src="/static/media/43f92ffb327ae345f47eca91d0d6089d.b70cd5f8ec9ac9141a2e.jpg" alt="img" className="wc-video img-fluid"/>
                                        <div className="qc-des">
                                            <p>To supply and install 1 No. 5 Panel Sunflex SF55i Sliding Folding Doors</p>
                                            <ul className="qc-list">
                                                <li>2 x enhancements added</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-6">
                                <div className="popup-request-form">
                                    <h2>Warning <br/>
                                    <span>Are you sure you want to remove this from your quote?</span>
                                    </h2>
                                    
                                    <a href="#" className="btn btn-primary delete-btn" role="button">Delete</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>;
    }
    }
    export default Review;