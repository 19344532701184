import YourTeam from '../../assets/images/your_team_icon.svg';
import YourGlass from '../../assets/images/your_glass_icon.svg';
import Review from '../../assets/images/review_icon.svg';
import TermsSign from '../../assets/images/terms_sign_icon.svg';
import Payments from '../../assets/images/payments_icon.svg';
import Progress from '../../assets/images/progress_icon.svg';
import AboutUs from '../../assets/images/about_us_icon.svg';
import ChatIcon from '../../assets/images/ionic-ios-chatbubbles.svg';

export default{
	TeamSvg: YourTeam,
	GlassSvg: YourGlass,
	ReviewSvg: Review,
    TermsSvg: TermsSign,
    PaymentsSvg: Payments,
    ProgressSvg: Progress,
    AboutSvg: AboutUs,
    ChatSvg: ChatIcon,
}