import React from 'react';
//import ReactDOM from 'react-dom/client';
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import PagesSvg from "../Constant/PagesSvg";
import Lottie from 'react-lottie';
import * as animationData from '../../assets/js/6699160.json'
import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';
var myTimer;
var myTimerD = '1';
class Team extends React.Component {
constructor(props) {
super(props);
this.state = {animate: false};
this.state = {animateClass: ''};
AOS.init({ duration : 2000});
}
componentDidMount(){
this.timer = setInterval(
() => this.setState({animateClass:'width-with-animation'}),
100,
);
}
componentWillUnmount(){
// this.unlisten();
}
render() {
const defaultOptions = {
loop: true,
autoplay: true,
animationData: animationData,
rendererSettings: {
preserveAspectRatio: 'xMidYMid slice'
}
};
return <div className="container-fluid team-page">
    <div className="row">
        <div className="col-md-3 px-0">
            <Sidebar />
        </div>
        <div className="col-md-9 position-relative">
            <div className="main-content">
                <div className="container">
                    <div className="row" data-aos="fade-right"
                        data-aos-offset="100"
                        data-aos-easing="ease-in-sine">
                        <div className="col">
                            <h1 className="main-heading">You’re success team </h1>
                        </div>
                    </div>
                    <div className={"row position-relative " + (this.state.animateClass != '' ? this.state.animateClass : 'width-without-animation')}>
                        <div className="col-lg-3 col-6">
                            <div className="team-member">
                                <div className="member-profile-pic">
                                    <img src={require('../../assets/images/amy.png')} alt="img" className="profile-pic img-fluid"/>
                                </div>
                                <h3>Amy Lockwood <br/>
                                <span>Sales</span>
                                </h3>
                                <ul className="team-member-contacts">
                                    <li><a href="#"><img src={PagesSvg.PhoneIconSvg} alt="icon" /></a></li>
                                    <li><a href="#"><img src={PagesSvg.MailIconSvg} alt="icon" /></a></li>
                                    <li><a href="#"><img src={PagesSvg.LinkedIconSvg} alt="icon" /></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 second-tm">
                            <div className="team-member">
                                <div className="member-profile-pic">
                                    <img src={require('../../assets/images/kevin.png')} alt="img" className="profile-pic img-fluid"/>
                                </div>
                                <h3>Kevin Richards <br/>
                                <span>Customer Success</span>
                                </h3>
                                <ul className="team-member-contacts">
                                    <li><a href="#"><img src={PagesSvg.PhoneIconSvg} alt="icon" /></a></li>
                                    <li><a href="#"><img src={PagesSvg.MailIconSvg} alt="icon" /></a></li>
                                    <li><a href="#"><img src={PagesSvg.LinkedIconSvg} alt="icon" /></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 third-tm">
                            <div className="team-member disable-tm">
                                <div className="member-profile-pic">
                                </div>
                                <h3>TBA<br/>
                                <span>Survey Partner</span>
                                </h3>
                                <ul className="team-member-contacts">
                                    <li><a href="#"><img src={PagesSvg.PhoneIconSvg} alt="icon" /></a></li>
                                    <li><a href="#"><img src={PagesSvg.MailIconSvg} alt="icon" /></a></li>
                                    <li><a href="#"><img src={PagesSvg.LinkedIconSvg} alt="icon" /></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 fourth-tm">
                            <div className="team-member disable-tm">
                                <div className="member-profile-pic">
                                </div>
                                <h3>TBA<br/>
                                <span>Lead Site Team</span>
                                </h3>
                                <ul className="team-member-contacts">
                                    <li><a href="#"><img src={PagesSvg.PhoneIconSvg} alt="icon" /></a></li>
                                    <li><a href="#"><img src={PagesSvg.MailIconSvg} alt="icon" /></a></li>
                                    <li><a href="#"><img src={PagesSvg.LinkedIconSvg} alt="icon" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
            <Footer/>
        </div>
    </div>
</div>;
}
}
export default Team;