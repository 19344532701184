import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Start from "./pages/Start/Start";
import Team from "./pages/Team/Team";
import Glass from "./pages/Glass/Glass";
import Review from "./pages/Review/Review";
import Terms from "./pages/Terms/Terms";
import Payments from "./pages/Payments/Payments";
import Progress from "./pages/Progress/Progress";
import About from "./pages/About/About";
import './assets/css/style.css';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
       <Route path="/"  element={<Start />} />
       <Route path="/team"  element={<Team />} />
       <Route path="/glass"  element={<Glass />} />
       <Route path="/review"  element={<Review />} />
       <Route path="/terms&Sign"  element={<Terms />} />
       <Route path="/payments"  element={<Payments />} />
       <Route path="/progress"  element={<Progress />} />
       <Route path="/about"  element={<About />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);