import React,{Component} from 'react';
//import ReactDOM from 'react-dom/client';
import $ from 'jquery';
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import EstimatesecSvg from "../Constant/EstimatesecSvg";
import background from "../../assets/images/close.svg";
import AOS from 'aos';
import 'aos/dist/aos.css';
class Glass extends React.Component {
constructor() {
super();
this.state = {
name: 'React'
};
AOS.init({ duration : 2000});
}
toggleSec(){
$(".estimate-content-section").toggleClass("collapsible-shutter");
}
closeModal(){

$("#welcome-modal").css('display','none');
}
render() {

return <div className="container-fluid glass-page">
    <div className="row">
        <div className="col-md-3 px-0">
            <Sidebar />
        </div>
        <div className="col-md-9 position-relative px-0">
            <div className="main-content align-items-start py-5">
                <div className="bg-image" data-aos="fade-down"
                    data-aos-easing="linear"
                data-aos-duration="500"> <img src={require('../../assets/images/yor_glass_page_bg.jpg')} alt="img" className="bg-img img-fluid"/></div>
                <div className="container position-relative h-100">
                    <div className="row" data-aos="fade-zoom-in"
                        data-aos-easing="ease-in-back"
                        data-aos-delay="1000"
                        data-aos-offset="0">
                        <div className="col-md-8">
                            <div className="estimate-content-section">
                                <div className="ecs-fixed-header">
                                    <ul className="estimate-heading-list">
                                        <li className="d-lg-flex justify-content-lg-between align-items-center">
                                            <div className="estimate-left-sec">
                                                <h3>Glazing Package Estimate.</h3>
                                                <p>Location: Foxbury, Brittains Lane, Sevenoaks, TN13 2JS.</p>
                                                
                                            </div>
                                            <div className="estimate-right-sec">
                                                <h3>Ref : Q-5772</h3>
                                                <p>Date added: 23/04/22</p>
                                            </div>
                                        </li>
                                        <li className="d-lg-flex justify-content-lg-between align-items-center">
                                            <div className="estimate-left-sec">
                                                <h3>Exterior Door 1 </h3>
                                                <p>Please confirm any enhancements and options below  </p>
                                                
                                            </div>
                                            <div class="estimate-right-sec d-flex"><a href="#" class="btn btn-primary active optn-btn" role="button">Option 1</a><a href="#" class="btn btn-primary optn-btn ms-3" role="button">Option 2</a></div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="ecs-scrolling-description-sec">
                                    <div className="main-wrap-sec d-lg-flex justify-content-lg-between">
                                        <div className="lft-sec">
                                            <h5>To Supply and Install the following items</h5>
                                            <p>Qty:  2  |  No of Panels:  3 | No Of Tracks: Triple Configurations: XXO Colour: RAL 7016M</p>
                                        </div>
                                        <div className="rgt-sec">
                                            <img src={require('../../assets/images/crittall.png')} alt="img" className="contract-logo img-fluid"/>
                                        </div>
                                    </div>
                                    <h5>Sliding Folding Door Quotation</h5>
                                    <div className="main-wrap-sec d-lg-flex justify-content-lg-between">
                                        <div className="lft-sec">
                                            
                                            <p>To supply and install 1 No. 5 Panel Sunflex
                                            SF55i Sliding Folding Doors</p>
                                        </div>
                                        <div className="rgt-sec">
                                            <h3>£ 9,569.00 + VAT</h3>
                                        </div>
                                    </div>
                                    <h5>Optional Extras / Enhancements</h5>
                                    <div className="main-wrap-sec d-lg-flex justify-content-lg-between">
                                        <div className="lft-sec">
                                            
                                            <p>Extra for manual integral Venetian blinds (per unit).</p>
                                        </div>
                                        <div className="rgt-sec">
                                            <h3> £ 375.00 + VAT</h3>
                                            <div className="rgt-sec-cta">
                                                <a href="#"><img src={EstimatesecSvg.ImgicoSvg} alt="icon" /></a>
                                                <a href="#" className="btn btn-primary add-btn" role="button">Add</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="main-wrap-sec d-lg-flex justify-content-lg-between">
                                        <div className="lft-sec">
                                            
                                            <p>Extra for Solar Control Coating to glass (per unit).</p>
                                        </div>
                                        <div className="rgt-sec">
                                            <h3> £ 170.00 + VAT</h3>
                                            <div className="rgt-sec-cta">
                                                <a href="#"><img src={EstimatesecSvg.ImgicoSvg} alt="icon" /></a>
                                                <a href="#" className="btn btn-primary add-btn" role="button">Add</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="main-wrap-sec d-lg-flex justify-content-lg-between">
                                        <div className="lft-sec">
                                            
                                            <p>Extra for Aluminium 190 x 25mm cill if req’d</p>
                                        </div>
                                        <div className="rgt-sec">
                                            <h3>£ 335.00 + VAT</h3>
                                            <div className="rgt-sec-cta">
                                                <a href="#"><img src={EstimatesecSvg.ImgicoSvg} alt="icon" /></a>
                                                <a href="#" className="btn btn-primary add-btn" role="button">Add</a>
                                            </div>
                                        </div>
                                    </div>
                                    <h5>Specification</h5>
                                    <ul>
                                        <li>* Sunflex SF55 folding sliding doors</li>
                                        <li>* Dimensions – 4625 x 2200mm Inc</li>
                                        <li>* Direct fix Aluminium</li>
                                        <li>* Polyamide thermally broken Aluminium</li>
                                        <li>* Polyester powder coated in RAL 7016 – Anthracite Grey</li>
                                        <li>* Double glazed with 4mm soft coat Low E toughened safety glass (Planithern) - Argon gas filled cavity</li>
                                        <li>* Low Level Track - No Cill (Flush)</li>
                                        <li>* Latch and Flush Handles in Satin Anodised Finish</li>
                                        <li>* Colour Coded Handles & Hinges in RAL 706 Anthracite Grey</li>
                                        <li>* Doors designed as 5 panels all folding to one end and stacking to outside</li>
                                        <li>* Includes Key locking to lead doors as standard     </li>
                                    </ul>
                                    <h5>Notes </h5>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum</p>
                                    <div className="brand-list">
                                        <img src={require('../../assets/images/ea29dc8fc0f66427a42e9a59f83e2ebd.png')} alt="img" className="bl-logo img-fluid"/>
                                        <img src={require('../../assets/images/b3c319347a57cd53e2ae737d2cd82871.png')} alt="img" className="bl-logo img-fluid"/>
                                        <img src={require('../../assets/images/c62acc282e87f4735e1202f5241b903a.png')} alt="img" className="bl-logo img-fluid"/>
                                        <img src={require('../../assets/images/11cb015aa2ec6815336448a01376f8d3.png')} alt="img" className="bl-logo img-fluid"/>
                                    </div>
                                    <div className="total-estimate">
                                        <div className="price-count">
                                            <h5>Option 1 </h5>
                                            <h5>Total: <span>£ 10,783.00 + VAT</span></h5>
                                        </div>
                                        <a href="#" className="btn btn-primary total-btn" role="button">Select this option</a>
                                    </div>
                                </div>
                                <div className="shutter-sec" onClick={this.toggleSec.bind(this)}>
                                    <p>Expand to view your glass</p>
                                    <div className="colapsible-icon"><img src={EstimatesecSvg.ColapsibleSvg} alt="icon" /></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 extra-btn-sec">
                            <div className="popup-cta-btn justify-content-around ms-md-auto">
                                <a href="#" className="btn img-popup-btn" data-bs-toggle="modal" href="#video-slider-modal" role="button"><img src={EstimatesecSvg.ImgicoSvg} alt="icon" /></a>
                                <a href="#" className="btn play-popup-btn" data-bs-toggle="modal" href="#exampleModalToggle" role="button"><img src={EstimatesecSvg.PlayicoSvg} alt="icon" /></a>
                                <a href="#" className="btn info-popup-btn" data-bs-toggle="modal" href="#exampleModalToggle3" role="button"><img src={EstimatesecSvg.InfoicoSvg} alt="icon" /></a>
                            </div>
                            <div className="pagination-sec">
                                <div className="pagination-scrollable">
                                    <ul className="pagination-list">
                                        <li><a href="#">1</a></li>
                                        <li><a href="#">2</a></li>
                                        <li><a href="#">3</a></li>
                                        <li><a href="#">4</a></li>
                                        <li><a href="#">5</a></li>
                                        <li><a href="#">6</a></li>
                                        <li><a href="#">7</a></li>
                                        <li><a href="#">8</a></li>
                                        <li><a href="#">9</a></li>
                                        <li><a href="#">10</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Footer/>
            
        </div>
    </div>
    <div class="modal blur-overlay fade welcome-overlay" data-aos="fade-zoom-in"
        data-aos-easing="ease-in-back"
        data-aos-delay="3000"
        data-aos-offset="0" id="welcome-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <div className="row align-items-center">
                        <div className="col-sm-6">
                            <h1>Welcome</h1>
                            <p>Here is a quick video showing you how to interact with your estimate to receive your quote.</p>
                            <a href="javascript:void(0)" onClick={this.closeModal.bind(this)} data-dismiss="modal" className="btn btn-primary start-now-btn" role="button">Start Now</a>
                        </div>
                        <div className="col-sm-6">
                            <div className="welcome-video-sec">
                                <img src={require('../../assets/images/welcome_video_popup.png')} alt="img" className="wc-video img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal blur-overlay fade finished-overlay" id="finishing-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <h1>Things to know before the next step</h1>
                            <div className="modal-scrolable-area">
                                <h2>Lead Time </h2>
                                <p>Lead time is approximately 2-3 weeks for a survey, 7-10 Working days to receive you working drawings and then 14-16 weeks from the date of signed and received manufacturing drawings and should take approximately 8-9 working days to install.</p>
                                <h2>Terms</h2>
                                <p>40% deposit with order, 50% prior to commencement on site and balance strictly on completion.</p>
                                <p>Any variations to contract following survey must be agreed in writing and will be itemised and added in full to the stage payment invoice due for payment prior to the commencement of installation.</p>
                                <h2>Review and Confirm</h2>
                                <p>On the next step you will be able to confirm your quote and make any change requests, this will confirm your price subject to survey.</p>
                                <h2>Guarantee</h2>
                                <p>Our products and workmanship are guaranteed for a period of 10 years from installation and are also insurance backed for your complete peace of mind.</p>
                                <p>If you have any questions please do not hesitate to e-mail or call us on: 0800 328 3028.</p>
                                <p>If you wish to view more images or read more on the products please visit : www.yesglazing.co.uk</p>
                                <p>or to meet and see the products in person, please feel free to book in to visit the showroom.</p>
                                <p>I do hope the above meets with your approval and I look forward to hearing how you wish to proceed.</p>
                                <p>Kind regards </p>
                                <p>— James Howard</p>
                                
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="about-video-sec">
                                <h4>About us & Our Processes</h4>
                                <img src={require('../../assets/images/aboutprocess01.png')} alt="img" className="wc-video img-fluid"/>
                                <img src={require('../../assets/images/aboutprocess02.png')} alt="img" className="wc-video img-fluid"/>
                                <img src={require('../../assets/images/aboutprocess03.png')} alt="img" className="wc-video img-fluid"/>
                                <a href="#" className="btn btn-primary start-now-btn" role="button">continue</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal video-play-modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content white-blur-bg">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ backgroundImage: `url(${background})` }}></button>
                <div class="modal-body btn" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" data-bs-dismiss="modal">
                    <img src={require('../../assets/images/pp-vdo.png')} alt="img" className="pp-video img-fluid"/>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade second-popup" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ backgroundImage: `url(${background})` }}></button>
                <div class="modal-body">
                    <img src={require('../../assets/images/pp-vdo.png')} alt="img" className="pp-video img-fluid"/>
                </div>
            </div>
        </div>
    </div>
    <div class="modal blur-overlay fade info-popup-overlay" id="exampleModalToggle3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <div className="row align-items-center">
                        <div className="col-12">
                            
                            <h5>Stonly.com <br/>Support info</h5>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal blur-overlay fade videos-slider-popup-overlay" id="video-slider-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <div className="row align-items-center">
                        <div className="col-12">
                            
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>;
}
}
export default Glass;